import React from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Loader from "./shared/Loader";

// Layouts
import Layout1 from "./shared/layouts/Layout1";

// Lazy load component
const lazy = (cb) =>
  loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

// ---
// Default application layout

export const DefaultLayout = Layout1;

// ---
// Document title template

export const titleTemplate = "%s - beSafe Premium";

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = "/";
export const adminDefaultRoute = "/accounts";
export const routes = [
  {
    path: "/",
    component: lazy(() => import("./components/pages/dashboard/Dashboard")),
    superUserPage: false,
  },
  {
    path: "/tunnels",
    component: lazy(() => import("./components/pages/tunnels/TunnelList")),
    superUserPage: false,
  },
  {
    path: "/policies",
    component: lazy(() => import("./components/pages/access_control/AccessControl")),
    superUserPage: false,
  },
  {
    path: "/threat-prevention",
    component: lazy(() => import("./components/pages/threat_preventions/ThreatPrevention")),
    superUserPage: false,
  },
  {
    path: "/ssl-inspection",
    component: lazy(() => import("./components/pages/ssl_inspection/SSLInspection")),
    superUserPage: false,
  },
  {
    path: "/accounts",
    component: lazy(() => import("./components/pages/superadmin/accounts/AccountsList")),
    superUserPage: true,
  },
  {
    path: "/administrators",
    component: lazy(() => import("./components/pages/superadmin/administrators/AdministratorsList")),
    superUserPage: true,
  },
  {
    path: "/maintenance",
    component: lazy(() => import("./components/pages/superadmin/maintenance/Maintenance")),
    superUserPage: true,
  },
  {
    path: "/users",
    component: lazy(() => import("./components/pages/superadmin/users/UsersList")),
    superUserPage: true,
  },
  {
    path: "/logs",
    component: lazy(() => import("./components/pages/logs_reports/LogsReports")),
    superUserPage: false,
  },
  {
    path: "/admin",
    component: lazy(() => import("./components/pages/administration/Admin")),
    superUserPage: false,
  },
  {
    path: "/firewalls-clusters",
    component: lazy(() => import("./components/pages/firewalls_clusters/FirewallsClusters")),
    superUserPage: false,
  },
  {
    path: "/licenses",
    component: lazy(() => import("./components/pages/licenses/Licenses")),
    superUserPage: false,
  },
];
